import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import ReactSelect from 'react-select';
import * as _ from 'lodash';
import Date from './Date';
import React from "react";

const CommunicationFilter = ({ t, filters }) => {

  return (
    <Row className="g-4 flex-column flex-xxl-row">
      <Col xl={12}>
        <Row className="g-3 mb-4 row-cols-1 row-cols-md-3">
          <Col>
            <Date
              t={t}
              filters={filters}
              placeholder={t("shipping-date")}
              item={{ field: 'created_at' }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withTranslation()(CommunicationFilter);
