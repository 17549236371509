/* eslint-disable react/prop-types */
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import useQsParams from "../../../Components/Hooks/QueryString";
import { getCommunicationCardsData, getOrdersFilters, getSalesCardsData, getSalesChartData } from "../../../slices/manager-panel/thunk";
import React, { useEffect, useState } from "react";

import { createSelector } from "reselect";
import { getFilters } from "../../../Components/Common/Filter";
import { isEmpty } from "lodash";
import CommunicationFilter from "../components/CommunicationFilter";
import NavTabRequest from "../../../Components/Common/NavTabRequest";
import Summary from "./TabsContent/Summary";
import Sms from "./TabsContent/Sms";
import SmsToken from "./TabsContent/SmsToken";
import Email from "./TabsContent/Email";
import Whatsapp from "./TabsContent/Whatsapp";


const CommunicationSummary = ({ t }) => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();
  const [, setFiltersParams] = useState({});
  const [type, setType] = useState(searchParams.get('type'));

  document.title = `${t('results-manager.heading')} | Opencashback`;

  const filters = useSelector((state) => state.ManagerPanel.filters);

  useEffect(() => {
    dispatch(getOrdersFilters());
  }, [dispatch]);

  const { communication } = useSelector(
    createSelector(
      (state) => state.ManagerPanel,
      ({ communication }) => ({ communication })
    )
  );


  useEffect(() => {
    getData();
  }, [dispatch, searchParams.toString()]);

  useEffect(() => {
    const newType = searchParams.get('type');
    setType(newType);
  }, [searchParams]);

  const filtersSummary = [
    { field: 'created_at' },
  ];

  const getData = () => {
    const type = searchParams.get('type');
    const params = type ? { type } : {};

    const appliedFilters = getFilters(filtersSummary, [
      { field: 'created_at', type: 'between_date' },
    ]);

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    setFiltersParams(params.filters);
    dispatch(getCommunicationCardsData(params));

  };

  const buildUrlWithFilters = (baseUrl) => {
    const url = new URL(baseUrl, window.location.origin);
    const appliedFilters = [
      { field: 'created_at', value: searchParams.get('created_at') },
      { field: 'channel', value: searchParams.get('channel') },
      { field: 'store_id', value: searchParams.get('store_id') },
    ];

    appliedFilters.forEach(({ field, value }) => {
      if (value) {
        url.searchParams.append(field, value);
      }
    });
    return url.toString();
  };

  const [activeTab, setActiveTab] = useState('1');

  const tabs = [
    {
      index: '1',
      label: t('results-manager.communication-summary.titles.general'),
      content: <Summary communication={communication} setActiveTab={setActiveTab} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: !searchParams.get('type'),
      value: {},
    },
    {
      index: '2',
      label: t('results-manager.communication-summary.titles.sms'),
      content: <Sms communication={communication} setActiveTab={setActiveTab} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: !searchParams.get('type'),
      value: {},
    },
    {
      index: '3',
      label: t('results-manager.communication-summary.titles.sms-token'),
      content: <SmsToken communication={communication} setActiveTab={setActiveTab} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: !searchParams.get('type'),
      value: {},
    },
    {
      index: '4',
      label: t('results-manager.communication-summary.titles.email'),
      content: <Email communication={communication} setActiveTab={setActiveTab} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: !searchParams.get('type'),
      value: {},
    },
    {
      index: '5',
      label: t('results-manager.communication-summary.titles.whatsapp'),
      content: <Whatsapp communication={communication} setActiveTab={setActiveTab} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: !searchParams.get('type'),
      value: {},
    },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            isLoading={filters.isLoading}
            title={t("results-manager.communication-summary.title")}
            pageTitle={t("results-manager.heading")}
          />

          <CommunicationFilter filters={filters.data} />

          <NavTabRequest items={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>

        </Container>
      </div>
    </>
  );
};

export default withTranslation()(CommunicationSummary);

