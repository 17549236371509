import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSalesCardsData as getSalesCardsDataApi,
  getSalesChartData as getSalesChartDataApi,
  getCashbackChartData as getCashbackChartDataApi,
  getCashbackCardsData as getCashbackCardsDataApi,
  getOrdersFilters as getOrdersFiltersApi,
  getCommunicationCards as getCommunicationCardsApi,
  getAnalyticalOrdersFilters as getAnalyticalOrdersFiltersApi,

} from "../../helpers/bff_helper";


const namespace = 'manager-panel';

export const getOrdersFilters = createAsyncThunk(
  `${namespace}/getAnalyticalOrdersReportFilters`,
  async (params) => {
    try {
      const response = await getAnalyticalOrdersFiltersApi();

      return response; 
    } catch (error) {
      return error;
    }
  }
);

export const getSalesCardsData = createAsyncThunk(
  `${namespace}/getSalesCardsData`,
  async (params) => {
    params.order = 'asc';
    try {
      const response = getSalesCardsDataApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSalesChartData = createAsyncThunk(
  `${namespace}/getSalesChartData `,
  async (params) => {
    try {
      const response = getSalesChartDataApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCommunicationCardsData = createAsyncThunk(
  `${namespace}/getCommunicationCardsData`,
  async (params) => {
    try {
      const response = await getCommunicationCardsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCashbackCardsData = createAsyncThunk(
  `${namespace}/getCashbackCardsData`,
  async (params) => {
    params.order = 'asc';

    try {
      const response = getCashbackCardsDataApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCashbackChartData = createAsyncThunk(
  `${namespace}/getCashbackChartData `,
  async (params) => {
    try {
      const response = getCashbackChartDataApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);
