/* eslint-disable react/prop-types */
import { Card as CardWrapper, CardHeader, CardBody, UncontrolledTooltip } from 'reactstrap';
import { formatCurrency } from '../../../helpers/format';
import React from 'react';
import { isNil } from 'lodash';

const generateSafeId = (text) =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .toLowerCase();

const Card = ({ title, link, data, icon, color, label, tooltipId, setActiveTab, index, dontNeedFormat, dontChangeTab }) => {

  const dynamicTooltipId = tooltipId || index ? generateSafeId(`tooltip-${title}-${index}`) : generateSafeId(`tooltip-${title}-${icon}`);

  return (
    <CardWrapper className='card-summary' style={{ cursor: index ? !dontChangeTab && 'pointer' : 'default' }} onClick={() => { (index && !dontChangeTab) && setActiveTab(index) }}>
      <CardHeader>
        <div className="card-responsive d-flex align-items-center justify-content-between">
          <h6 className="m-0 text-uppercase" >
            {title}
            <i
              className="bx ri-question-line align-middle ms-2"
              id={`${dynamicTooltipId}`}></i>

            <UncontrolledTooltip target={`${dynamicTooltipId}`}>
              {label}
            </UncontrolledTooltip>
          </h6>

          {!index ? (
            link && <a href={link} className="d-flex align-items-center">
              <h6 className="fs-7 ff-secondary mb-0 text-uppercase">Detalhes
                <i className="bx bx-link-external align-middle ms-2"></i>
              </h6>
            </a>
          ) :
            !dontChangeTab && (
              <span className="d-flex align-items-center">
                <i className="bx bx-link-external align-middle ms-2"></i>
              </span>
            )
          }
        </div>
      </CardHeader>

      <CardBody>
        <div className="card-body-responsive d-flex align-items-end justify-content-between">
          <div>
            <h4 className="fs-24 fw-semibold mb-3">
              <span className="counter-value">{
                dontNeedFormat
                  ?
                  data?.value1
                  :
                  formatCurrency(data?.value1, { maximumFractionDigits: 2 })
              }</span>
            </h4>
            {!isNil(data?.value2) ?
              data?.label == "Ticket médio" ?
                <h6 className="fs-7 ff-secondary mb-0">
                  {data?.value2 > 0 && '+'}{`${Math.round(data?.value2 * 100)}%`} comparado ao total
                </h6>
                :
                <h6 className="fs-7 ff-secondary mb-0">
                  {`${Math.round(data?.value2 * 100)}%`} do total
                </h6>
              :
              <h6 className="fs-7 ff-secondary mb-0" style={{ height: '16px' }}>
              </h6>
            }

          </div>

          <div className="avatar-sm avatar-responsive flex-shrink-0">
            <span className={`avatar-title rounded fs-3 ${color}`}>
              <i className={icon}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </CardWrapper >
  );
};

export default Card;
