import React, { useEffect, useState, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import Filter, { getFilters } from '../../../Components/Common/Filter';

import { createSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import {
  formatDocument,
  formatDate,
  formatCellphone,
} from '../../../helpers/format';
import { isEmpty } from 'lodash';
import useQsParams from '../../../Components/Hooks/QueryString';
import { cleanupStoreHeaders } from '../../../helpers/api_helper';

import { getShippingHistory, getShippingHistoryFilters } from '../../../slices/communication/thunk';
import TableShippingHistory from './components/TableShippingHistory';
import { translations } from './statuses';
import { Link } from 'react-router-dom';

const ShippingHistory = (props) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useQsParams();

  const communicationState = createSelector(
    (state) => state.Communication.communications.history,
    (history) => history
  );

  const loadingState = createSelector(
    (state) => state.Communication.communications.isLoading,
    (isLoading) => isLoading
  );

  const metaState = createSelector(
    (state) => state.Communication.meta,
    (meta) => meta
  );

  const filtersState = createSelector(
    (state) => state.Communication.filters.data,
    (filters) => filters
  );


  const communication = useSelector(communicationState);
  const isLoading = useSelector(loadingState);
  const meta = useSelector(metaState);
  const orderFilters = useSelector(filtersState);

  const [filtersParams, setFiltersParams] = useState({});

  useEffect(() => {
    getData();
    dispatch(getShippingHistoryFilters());
  }, [dispatch, searchParams.toString()]);

  const filters = [
    {
      field: 'status',
      name: "Status",
      type: 'checkbox',
      values: [
        {
          label: 'Sucesso',
          value: 'success',
        },
        {
          label: "Erro",
          value: 'error',
        },
      ],
    },
    {
      field: 'updated_at',
      name: "Data de atualização",
      type: 'date',
    },
    {
      field: 'template_title',
      name: "Nome do template",
      type: 'checkbox',
    },
    {
      field: 'rule',
      name: "Gatilho",
      type: 'checkbox',
    },
  ];

  const methods = [
    {
      label: props.t(`email`),
      value: 'Email',
    },
    {
      label: props.t(`sms`),
      value: 'Sms',
    },
    {
      label: props.t(`whatsapp`),
      value: 'Whatsapp',
    }
  ];

  const getData = () => {
    const order = 'desc';
    const params = { page: searchParams.get('page') ?? 1, order };

    const appliedFilters = getFilters(filters, [
      { field: 'receiver', type: 'like' },
      { field: 'customer_document', type: 'like' },
      { field: 'method', type: 'in' },
      { field: 'sent_at', type: 'between_date' },

    ]);

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    const storesIds = appliedFilters?.filterBy;
    if (storesIds) {
      sessionStorage.setItem('filterByStores', storesIds?.join(','));
    }

    setFiltersParams(params.filters);
    dispatch(getShippingHistory(params));
  };

  const getTranslationOrderStatus = (status) => {
    return translations[status];
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t('document'),
        accessor: 'customer_document',
        filterable: false,
        Cell: (props) => {
          if (props?.row?.original?.customer_document) {
            return (
              <Link
                to={`/carteiras/detalhes/${props?.row?.original?.customer_document}`}
                style={{ borderBottom: '1px solid lightgray' }}
              >
                {formatDocument(props?.row?.original?.customer_document)}
              </Link>
            )
          } else {
            return '-';
          }
        },
        reportProps: {
          formatter: {
            type: 'mask',
            properties: { custom: 'document' },
          },
        },
      },
      {
        Header: props.t('shipping-date'),
        accessor: 'sent_at',
        filterable: false,
        Cell: (props) => formatDate(props.row.original.sent_at),
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        Header: props.t('shipping-method'),
        accessor: 'method',
        filterable: false,
        Cell: (info) => {
          const value = info.row.original.method || '-';
          if (value !== '-') {
            return props.t(`communication-status.${info.row.original.method}`);
          }
          return value;
        },
        reportProps: {
          accessor: 'method',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  value: props.t(`email`),
                  key: 'Email',
                },
                {
                  value: props.t(`sms`),
                  key: 'Sms',
                },
                {
                  value: props.t(`whatsapp`),
                  key: 'Whatsapp',
                }
              ],
            },
          },
        },
      },
      {
        Header: props.t('trigger'),
        accessor: 'rule',
        filterable: false,
        Cell: (props) => props.row.original.rule || '-',
        reportProps: {
          accessor: 'rule',
        },
      },
      {
        Header: props.t('recipient'),
        accessor: 'receiver',
        filterable: false,
        Cell: (props) => {
          const value = props.row.original.receiver || '-';
          const isEmail = value.includes('@');
          if (value !== '-') {
            if (isEmail) {
              return value
            } else {
              return formatCellphone(props.row.original.receiver);
            }
          }
          return value;
        },
        reportProps: {
          accessor: 'receiver',
        },
      },
      {
        Header: props.t('type'),
        accessor: 'type',
        filterable: false,
        Cell: (raw) => {
          const type = raw.row.original.type || '-'

          if (type !== '-') {
            return props.t(`${raw.row.original.type}`)
          }
          return type
        },
        reportProps: {
          accessor: 'type',
        },
      },
      {
        Header: props.t('status'),
        accessor: 'status',
        filterable: false,
        Cell: (props) => {
          const translation = getTranslationOrderStatus(
            props.row.original.status
          );

          return (
            <React.Fragment>
              <span className={`badge rounded-pill ${translation?.color}`}>
                {translation?.label}
              </span>
            </React.Fragment>
          );
        },
        reportProps: {
          accessor: 'status',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'success',
                  value: getTranslationOrderStatus('success')?.label,
                },
                {
                  key: 'error',
                  value: getTranslationOrderStatus('error')?.label,
                },
              ],
            },
          },
        },
      },
      {
        Header: props.t('update-date'),
        accessor: 'updated_at',
        filterable: false,
        Cell: (props) => formatDate(props.row.original.updated_at),
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        Header: props.t('template-name'),
        accessor: 'template_title',
        filterable: false,
        Cell: (props) => props.row.original.template_title || '-',
        reportProps: {
          accessor: 'template_title',
        },
      },
    ],
    []
  );

  document.title = 'Histórico de disparos | Opencashback';

  return (
    <React.Fragment>
      <TableShippingHistory
        props={props}
        columns={columns}
        method={methods}
        searchParams={searchParams}
        isLoading={isLoading}
        communication={communication}
        filtersParams={filtersParams}
        meta={meta}
        page={searchParams.get('page')}
        setPage={(p) => setSearchParams('page', p)}
      />

      <Filter
        filters={filters}
        values={orderFilters}
        onClearFilter={cleanupStoreHeaders}
        localePrefix="communication-history"
        showUserStoresFilter
      />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(ShippingHistory);
