/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import useQsParams from '../Hooks/QueryString';

export default function NavTabRequest({ items, activeTab, setActiveTab }) {
  const { searchParams, setSearchParamsAsObject } = useQsParams();

  const toggleTab = (tabIndex) => {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex);
    }
  };

  const onClickTab = (item, isActive) => {
    const payload = { preset: item?.key, ...item?.value };

    const fieldsToRemove = items?.reduce((carret, item) => {
      const keysArr = Object.keys(item?.value);
      const keysMap = keysArr.map((k) => ({ [k]: '' }));
      const empties = Object.assign({}, ...keysMap);
      return { ...carret, ...empties };
    }, {});

    const params = !isActive
      ? { ...fieldsToRemove, ...payload }
      : { ...fieldsToRemove, preset: '' };

    setSearchParamsAsObject({ ...params, page: 1 });
  };

  // Executa onClickTab sempre que activeTab mudar
  useEffect(() => {
    const currentItem = items.find((item) => item.index === activeTab);
    if (currentItem) {
      const isActive =
        currentItem.isActive || searchParams.get('preset') === currentItem.key;
      onClickTab(currentItem, isActive);
    }
  }, [activeTab]); // Observa mudanças em activeTab

  useEffect(() => {
    const typeActive = searchParams.get('type');
    if (typeActive) {
      items.forEach((item) => {
        if (item.value.type === typeActive) {
          toggleTab(item.index);
        }
      });
    }
  }, []); // Roda apenas no mount

  return (
    <div className="d-flex flex-column">
      <Row>
        <Col>
          <Card>
            <CardHeader className="px-2">
              <Nav
                tabs
                className="nav nav-justified nav-tabs-custom rounded card-header-tabs border-bottom-0"
              >
                {items.map((item) => {
                  const isActive =
                    item?.isActive || searchParams.get('preset') === item.key;
                  return (
                    <NavItem key={item.index}>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames(
                          {
                            active: activeTab === item.index,
                            'bg-secondary': activeTab === item.index,
                          },
                          'text-uppercase fw-semibold'
                        )}
                        onClick={() => toggleTab(item.index)}
                      >
                        {item.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <TabContent activeTab={activeTab} className="text-muted">
        {items.map((item) => (
          <TabPane key={item.index} tabId={item.index}>
            {item.content}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
}
